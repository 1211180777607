<script lang="ts">
  import { Router, Route } from "svelte-routing";
  import About from "./About.svelte";

  import BMPGrid from "./BMPGrid.svelte";
  import Practice from "./Practice.svelte";
</script>

<header>
  <a href="/">
    <div class="title">
      <h1>GRASP</h1>
      <h3>Tools for Community Resilience</h3>
    </div>
  </a>

  <div class="nav">
    <a class="link hide-mobile" href="/about">About</a>
    <!-- <a class="link">ES</a> -->
  </div>
</header>

<Router>
  <Route path="/">
    <main>
      <div class="hero-image">
        <iframe
          src="https://player.vimeo.com/video/699509449?h=639e88a15e"
          width="100%"
          height="300"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="Business Resilience Toolkit Intro"
        />
      </div>
      <section class="hero">
        <div class="hide-mobile">
          <iframe
            src="https://player.vimeo.com/video/699509449?h=639e88a15e"
            width="100%"
            height="300"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title="Business Resilience Toolkit Intro"
          />
        </div>
        <div class="section-titles">
          <h1>BUSINESS RESILIENCE TOOLKIT</h1>
          <h2>For Auto Salvage Shops</h2>

          <div class="mobile-about">
            <a class="link" href="/about"> <h2>Learn more</h2></a>
          </div>
        </div>
      </section>

      <section class="divider">
        With the help of auto shops in Sunset Park, community members,
        advocates, scientists, and researchers, UPROSE has created this Business
        Resilience Toolkit with best practices to address extreme weather risks
        and vulnerabilities. We hope that these best practices help make your
        shop adaptable to climate-related emergencies such as flooding and
        storms, and keep our community safe from exposure to fugitive chemicals.
      </section>

      <BMPGrid />

      <section class="divider no-bg">
        This toolkit was developed by the Business Innovations for Resilience
        and Community Health (BIRCH) project, a Grassroots Research to Action in
        Sunset Park (GRASP) initiative, and was informed by Sunset Park
        community stakeholders. GRASP is the Grassroots Research to Action in
        Sunset Park research team composed of UPROSE, NYC-EJA, the RAND
        Corporation, and The LifeLine Group. It formed in the aftermath of
        Superstorm Sandy. This community-based participatory research focused on
        reducing the risk of “fugitive chemicals” from auto-related businesses
        in flooding or storm surge scenarios. While this toolkit was developed
        initially for the Sunset Park community in Brooklyn, the material can be
        used widely across a variety of communities and settings. The Business
        Innovations for Resilience and Community Health (BIRCH), an initiative
        of Grassroots Research to Action in Sunset Park (GRASP), is funded by
        the NIEHS: National Institute of Environmental Health Sciences.
      </section>
    </main>
  </Route>
  <Route path="practice/:id" let:params>
    <Practice id={params.id} />
  </Route>
  <Route path="about">
    <About />
  </Route>
</Router>

<footer>
  <p>
    The Business Resilience Toolkit is a product of the <a
      target="_blank"
      href="https://www.uprose.org/climate-justice-center"
      >Sunset Park Climate Justice Center</a
    > at UPROSE.
  </p>
  <p>
    Find us on <a href="https://twitter.com/UPROSE" target="_blank">Twitter</a>,
    <a href="https://www.instagram.com/uprosebrooklyn/" target="_blank"
      >Instagram</a
    >, <a href="https://www.facebook.com/uproseBk/" target="_blank">Facebook</a>
    <br />
    © 2014-2022 UPROSE. All Rights Reserved.
  </p>
</footer>

<style lang="scss">
  $light-text: white;
  $blue: rgb(41, 62, 101);
  $red: rgb(173, 60, 74);
  $green: #65b670;
  $orange: #ff8103;
  $dark-text: $orange;
  $background-accent: $green;

  h2,
  h3 {
    color: $dark-text;
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 900px) {
      align-items: center;
    }

    .uprose-logo {
      width: 10rem;
      img {
        width: 100%;
      }
    }

    a {
      color: $dark-text;
    }

    .title {
      /* border: 1px solid rgb(173, 60, 74); */
      /* border: 1px solid $light-text; */

      padding: 1rem;
      border-radius: 1rem;
      width: 100%;

      @media screen and (max-width: 900px) {
        padding: 0 1rem;
        text-align: center;
        margin-bottom: 2rem;

        h3 {
          font-size: 1.5rem;
        }
      }
    }

    .uprose-title {
      display: flex;
      align-items: center;
      /* padding-top: 1rem; */

      @media screen and (max-width: 1050px) {
        display: none;
      }

      span {
        padding-top: 1.2rem;
        padding-right: 1rem;
        font-size: 1.5rem;
        color: $blue;
      }
    }

    h1 {
      color: $dark-text;
      margin: 0;
      font-size: 4rem;
    }

    h3 {
      color: $green;

      margin: 0;
      font-size: 2rem;
    }

    .link {
      color: $dark-text;
      font-size: 1.5rem;
      cursor: pointer;
      /* border: 1px solid rgb(173, 60, 74); */
      padding: 0.5rem 1rem;
      border-radius: 1rem;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .nav {
      width: 550px;
      display: flex;
      justify-content: flex-end;
      /* padding-top: 1rem; */

      @media screen and (max-width: 900px) {
        width: 100%;
        /* display: none; */
      }
    }
  }

  .hero-image {
    display: none;
    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  .hero {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
  }

  .hide-mobile {
    display: block;
    @media screen and (max-width: 900px) {
      display: none;
    }

    a {
      color: $orange;
    }
  }

  .mobile-about {
    display: none;
    color: $orange !important;
    text-align: left;

    @media screen and (max-width: 900px) {
      display: block;
    }

    a {
      color: $green;

      h2 {
        color: $green;
      }
      /* font-size: 2rem; */
      text-align: left;
      font-family: "Rubik", sans-serif;
    }
  }

  main {
    text-align: center;
    margin: 0 auto;

    section {
      display: flex;
      padding: 2rem 2rem 5rem;
      align-items: center;

      .section-titles {
        text-align: left;
        padding: 1rem;

        h1 {
          margin-bottom: 1rem;
          font-size: 3rem;

          @media screen and (max-width: 900px) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    h1 {
      font-size: 4rem;
      color: $dark-text;
    }
  }

  .divider {
    background-color: $background-accent;
    color: white;
    font-size: 1rem;
    text-align: center;
    justify-content: center;
    padding: 4rem;
    box-shadow: 0 0 3px 3px $background-accent;
    margin-bottom: 3rem;
  }

  .no-bg {
    background-color: transparent;
    box-shadow: none;
    color: black;
    text-align: left;

    @media screen and (max-width: 900px) {
      font-size: 1rem;
      padding: 1rem;
    }
  }

  footer {
    a {
      text-decoration: underline;
      color: $light-text;
    }

    background-color: $green;
    color: $light-text;
    padding: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 900px) {
      text-align: center;
      flex-direction: column;
    }
  }
</style>
