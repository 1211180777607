<script lang="ts">
  import { onMount } from "svelte";

  import { fetchBMPs, urlFor } from "./sanity";

  onMount(async () => {
    const bmps = await fetchBMPs();
  });
</script>

<div class="grid">
  {#await fetchBMPs() then items}
    {#each items as item}
      {#if item.mainImage || item.icon}
        <a href={`/practice/${item.id}`} class="item-root">
          <div class="item">
            <img
              src={urlFor(item.icon || item.mainImage)
                .width(400)
                .url()}
            />
            <h2>{item.title}</h2>
          </div>
        </a>
      {/if}
    {/each}
  {/await}
</div>

<style lang="scss">
  .grid {
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-auto-rows: minmax(350px, auto);
    gap: 1rem;
  }

  .item-root {
    display: flex;
  }

  .item {
    border: 2px solid darkgray;
    border-radius: 0.2rem;
    box-shadow: rgb(0 0 0 / 6%) 0px 4px 4px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 1rem;

    img {
      width: 100%;
    }

    h2 {
      color: black;
      margin: 1rem;
      min-height: 60px;
    }
  }
</style>
