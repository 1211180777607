<main>
  <p class="intro">
    Mixed-use neighborhoods (i.e. those that combine residential and commercial
    spaces) are developing rapidly. In these industrialized communities,
    commercial development brings convenience and economic activity but also
    risks, namely those from fugitive chemicals. The issue isn’t limited to one
    sector – chemical products are found everywhere – in homes, shops,
    restaurants, industries large and small. With increased flooding brought
    about by climate change, these chemicals can be unintentionally released
    into the community. Given the complexity of the issue, we don’t yet know
    everything about the particular health risks posed by fugitive chemicals,
    but we do know that many are harmful to the people and ecosystems who come
    into contact with them.
  </p>

  <h1>Who We Are</h1>

  <h2>UPROSE</h2>

  <p>
    Founded in 1964, UPROSE is Brooklyn’s oldest Latino community based
    organization. Today, UPROSE is an intergenerational, multi-racial,
    nationally-recognized community organization that promotes the
    sustainability of the Sunset Park community in Brooklyn through community
    organizing, education, leadership development, and cultural/artistic
    expression. UPROSE’s work encompasses a variety of environmental and public
    health initiatives, from the development of the waterfront and local
    brownfields in Sunset Park, to transportation, open space, and air quality
    needs, to educational and youth empowerment campaigns.
  </p>

  <h2>New York City Environmental Justice Alliance (NYC-EJA)</h2>
  <p>
    Founded in 1991, the New York City Environmental Justice Alliance (NYC-EJA)
    is a non-profit, 501(c)3 citywide membership network linking grassroots
    organizations from low-income neighborhoods and communities of color in
    their struggle for environmental justice. NYC-EJA empowers its member
    organizations to advocate for improved environmental conditions and against
    inequitable environmental burdens by the coordination of campaigns designed
    to inform City and State policies. Through our efforts, member organizations
    coalesce around specific common issues that threaten the ability for
    low-income communities of color to thrive. NYC-EJA is led by the
    community-based organizations that it serves, with its board elected by its
    member groups, who set policy and guide program development.
  </p>

  <h2>The RAND Corporation</h2>
  <p>
    The RAND Corporation is a nonprofit institution that helps improve policy
    and decision making through research and analysis. RAND focuses on the
    issues that matter most such as health, education, national security,
    international affairs, law and business, the environment, and more. As a
    nonpartisan organization, RAND is widely respected for operating independent
    of political and commercial pressures. Our core values are quality and
    objectivity. Research is carried out by five units that address social and
    economic policy issues; by three federally funded research and development
    centers that focus on national security; by professors and graduate students
    at the Pardee RAND Graduate School; and by RAND Europe, an independently
    chartered European affiliate.
  </p>

  <h2>The Lifeline Group</h2>
  <p>
    Founded in 2000 as a non-profit educational organization, The LifeLine
    Group’s [LifeLine] mission has been to create and apply state-of-the-art
    exposure and risk assessment tools, pioneer better approaches for exposure
    science, provide mentorship and community collaboration, and make LifeLine’s
    software and databases freely available. Working throughout North America,
    LifeLine has developed multiple assessment models addressing aggregate and
    cumulative risk concepts, exposure-based chemical prioritization, dietary
    and activity profiling for unique communities (including tribal and
    ethnically defined communities) and post-disaster chemical hazard profiling
    for communities. Its work has been supported by the National Institutes of
    Health (especially the National Institute of Environmental Health Sciences),
    the US Environmental Protection Agency, the Consumer Product Safety
    Commission as well as several stage agencies, especially those of Alaska and
    California. LifeLine has provided scientific support for novel exposure and
    risk assessment initiatives of Health Canada for consumer product safety,
    food safety and environmental protection. The organization is dedicated to
    applying its scientific expertise to community health initiatives, risk
    mitigation and government policy development.
  </p>

  <h1>The need for a new framework for environmental interventions</h1>
  <p>
    There have been unintended consequences of past efforts to improve the
    health and environment in industrialized communities. Reducing pollution,
    implementing climate adaptation measures, creating green spaces and public
    amenities, rezoning to separate industrial uses: these all may have had the
    intended effect of improving the environment, but also tended to drive out
    the community that lived there due to development and gentrification. Our
    framework for intervention prioritizes communities – not removing industry
    but encouraging greener and more resilient practices. The GRASP project
    focuses on working with the industrial business community, and in particular
    with small autobody shops.
  </p>
</main>

<style lang="scss">
  $light-text: white;
  $blue: rgb(41, 62, 101);
  $red: rgb(173, 60, 74);
  $green: #65b670;
  $orange: #ff8103;
  $dark-text: $orange;
  $background-accent: $green;

  main {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 5rem;

    .intro {
      font-size: 1.5rem;
    }

    h1 {
      color: $dark-text;
    }

    h2 {
      color: $green;
    }
  }
</style>
