import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
const client = sanityClient({
    projectId: "n9q5gy7w",
    dataset: "production",
    useCdn: true,
});
const imgBuilder = imageUrlBuilder(client);
export const fetchBMPs = async () => {
    console.log("fetching BMPs");
    const query = '*[_type == "practice"]';
    const bmps = await client.fetch(query);
    return bmps;
};
export const fetchId = async (id) => {
    console.log("fetching id");
    const query = `*[_type == "practice" && id == $id ]`;
    const params = { id: parseInt(id) };
    const bmp = await client.fetch(query, params);
    console.log("BMP", bmp[0]);
    return bmp[0];
};
export const urlFor = (source) => {
    return imgBuilder.image(source);
};
