<script lang="ts">
  import type { BlockProps } from "@portabletext/svelte";
  import { destroy_block } from "svelte/internal";

  export let portableText: BlockProps;

  $: block = portableText.block;
  $: style = block.style;
</script>

{#if style === "h1"}
  <h1><slot /></h1>
{:else if style === "h2"}
  <h2><slot /></h2>
{:else if style === "h3"}
  <h3><slot /></h3>
{:else}
  <slot />
{/if}

<style lang="scss">
  $blue: rgb(41, 62, 101);
  $orange: #ff8103;
  $green: #65b670;

  $dark-text: $green;

  h1,
  h2,
  h3 {
    color: $dark-text;
  }

  h2 {
    font-size: 2rem;
  }
</style>
