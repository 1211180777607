<script lang="ts">
  import { BMP, fetchId, urlFor } from "./sanity";
  import PortableText from "@portabletext/svelte";
  import Typography from "./Typography.svelte";

  export let id: string;
</script>

<main>
  {#await fetchId(id) then bmp}
    <section>
      <h1>{bmp.title}</h1>
      <div class="content">
        <div class="image">
          <img src={urlFor(bmp.mainImage).url()} />
        </div>

        <div class="portable-text">
          {#if bmp.body}
            <PortableText
              blocks={bmp.body}
              serializers={{
                blockStyles: {
                  normal: Typography,
                  h1: Typography,
                  h2: Typography,
                  h3: Typography,
                },
              }}
            />
          {/if}
        </div>
      </div>
    </section>

    {#if bmp.actionable_items}
      <section class="portable-text">
        <PortableText
          blocks={bmp.actionable_items}
          serializers={{
            blockStyles: {
              normal: Typography,
              h1: Typography,
              h2: Typography,
              h3: Typography,
            },
          }}
        />
      </section>
    {/if}

    {#if bmp.storm_items}
      <section class="portable-text storm">
        <PortableText
          blocks={bmp.storm_items}
          serializers={{
            blockStyles: {
              normal: Typography,
              h1: Typography,
              h2: Typography,
              h3: Typography,
            },
          }}
        />
      </section>
    {/if}
  {/await}
</main>

<style lang="scss">
  /* $blue: rgb(41, 62, 101); */
  $orange: #ff8103;
  $green: #65b670;

  $dark-text: $orange;
  $light-text: lightgray;
  $red: rgb(173, 60, 74);

  main {
    padding-bottom: 4rem;
  }

  section {
    padding: 2rem;
    padding-top: 0;
  }

  h1 {
    font-size: 3rem;
    color: $dark-text;

    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }

  .image {
    flex: 1;

    img {
      width: 100%;
    }
  }

  .content {
    display: flex;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .portable-text {
    flex: 1;
    margin-left: 2rem;
    font-size: 1.5rem;
    color: rgb(101, 98, 96);
  }

  .divider {
    background-color: $green;
    color: $green;
    font-size: 1.5rem;
    text-align: center;
    justify-content: center;
    padding: 4rem;
    margin-bottom: 3rem;
  }

  .storm {
    border-top: 1px solid $green;
    /* background-color: $red; */
    /* color: white; */
    padding: 4rem;
    margin-left: 0;
  }
</style>
